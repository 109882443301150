<template>
  <v-app class="specialBG mx-0 my-0">
    <v-main >
      <HelloWorld/>
    </v-main>
  </v-app>
</template>
<style scoped>
  .specialBG{
    
 background: url(./assets/bg.jpg)!important;
    background-repeat: no-repeat  !important; 
    background-position: top center !important;
    background-size: cover!important;
    
  background-blend-mode: darken!important;
  }

</style>
<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

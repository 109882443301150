<template>
  <v-row class="fill-height mx-0 my-0">
    <v-col cols="12">
      <v-row align="center" justify="center" class="mx-0 my-0">
        <v-col  cols="12">
          <v-img
          class="mx-auto "
            elevation="5"
          
            :src="
              require('../assets/natlogo.png') 
            "
            max-width="200px"
          ></v-img>
        </v-col>
      </v-row>
      <v-row
        align="center"
        justify="center"
        v-for="(li, ix) in links"
        :key="ix"
      >
        <v-col cols="10" sm="3"
          >
          <slide-x-transition>
          <v-btn :class="'blurrybg customBtt '+li.hoverColor+''" target="_blank" :href="li.ref" large block>
               <v-icon
        right
        class="mx-2"
        dark
      >
        {{ li.icon  }}
      </v-icon>  {{ li.name }}
            
            </v-btn>
          </slide-x-transition>
            </v-col
        >
      </v-row>
    </v-col>
  </v-row>
</template>
<style scoped>
.twitchBtt::before{
   background-color: #6441a5 !important;
}
.instaBtt::before{
  /* background: rgb(64,93,230); */
background: linear-gradient(90deg, rgba(64,93,230,1) 0%, rgba(91,81,216,1) 10%, rgba(131,58,180,1) 21%, rgba(193,53,132,1) 31%, rgba(225,48,108,1) 43%, rgba(253,29,29,1) 54%, rgba(245,96,64,1) 67%, rgba(247,119,55,1) 77%, rgba(252,175,69,1) 89%, rgba(255,220,128,1) 100%);
}
.snapBtt::before{
   background-color: #FFFC00 !important;
}
.discordBtt::before{
   background-color: #5865F2 !important; 
}
.twitterBtt::before{
   background-color: #00acee !important;
}

.customBtt:hover::before{
 
   opacity: 1 !important; 
 }


</style>
<script>
import { mdiDiscord, mdiSnapchat, mdiTwitter } from '@mdi/js';
import { mdiTwitch } from '@mdi/js';

import { mdiInstagram } from '@mdi/js';

export default {
   
  name: "HelloWorld",

  data: () => ({
    links: [
      {
        name: "Twitch",
        ref: "https://nataliappe.tv",
        icon: mdiTwitch,
        hoverColor:'twitchBtt',
      },
      {
        name: "Instagram",
        ref: "https://www.instagram.com/natalia.ppe",
        icon: mdiInstagram,
        hoverColor:'instaBtt',
        
      },
      {
        name: "Discord",
        ref: "https://discord.gg/j9kfz5nShE",
        icon: mdiDiscord,
        hoverColor:'discordBtt',
        
      },
      {
        name: "Snapchat",
        ref: "https://www.snapchat.com/add/natalia.ppe",
        icon: mdiSnapchat,
        hoverColor:'snapBtt',
        
      },
      {
        name: "Twitter",
        ref: "https://twitter.com/nataliappe",
        icon: mdiTwitter,
        hoverColor:'twitterBtt',
        
      },
    ],
  }),
};
</script>
